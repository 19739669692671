import {Theme} from "@material-ui/core";
import {createMuiTheme} from '@material-ui/core/styles';

export let theme: Theme;
theme = createMuiTheme({
    palette: {
        primary: {
            light: "#498fec",
            main: "#5F74FF",
            dark: "#1350a2",
            contrastText: "#fff"
        },
        secondary: {
            light: "#d15353",
            main: "#c62828",
            dark: "#8a1c1c",
            contrastText: "#fff"
        },

    },
    typography: {
        fontFamily: "MuseoSans, Roboto, Helvetica, Arial, sans-serif",
        h1: {fontSize: 26, fontWeight: 900},
        h2: {fontSize: 24, fontWeight: 900},
        h3: {fontSize: 22, fontWeight: 700},
        h4: {fontSize: 20, fontWeight: 700},
        h5: {fontSize: 18, fontWeight: 500},
        h6: {fontSize: 16, fontWeight: 500},
        subtitle1: {fontSize: 14, fontWeight: 300},
        subtitle2: {fontSize: 12, fontWeight: 300},
        body1: {fontSize: 14, fontWeight: 100},
        body2: {fontSize: 12, fontWeight: 100},
        caption: {fontSize: 12, fontWeight: 300},
        button: {fontSize: 14, fontWeight: 300},
        // h1: {fontSize: 26, fontWeight: 900},
        // h2: {fontSize: 24, fontWeight: 900},
        // h3: {fontSize: 22, fontWeight: 700},
        // h4: {fontSize: 20, fontWeight: 700},
        // h5: {fontSize: 18, fontWeight: 500},
        // h6: {fontSize: 16, fontWeight: 500},
        // subtitle1: {fontSize: 14, fontWeight: 300},
        // subtitle2: {fontSize: 12, fontWeight: 300},
        // body1: {fontSize: 14, fontWeight: 100},
        // body2: {fontSize: 12, fontWeight: 100}
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        }
    }
});
