import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import {Alert} from "@material-ui/lab";

interface YoleSnackbarsProps extends React.HTMLAttributes<HTMLDivElement> {
    horizontal?: "left" | "center" | "right";
    vertical?: "top" | "bottom";
}

export type MessageType = "success" | "info" | "warning" | "error";

export class YoleSnackbarsState {
    icon?: string;
    show: boolean = false;
    text?: string;
    duration?: number = 2000;
    messageType?: MessageType;
    horizontal?: "left" | "center" | "right";
    vertical?: "top" | "bottom";
}


export default class YoleSnackbars extends React.Component<YoleSnackbarsProps, YoleSnackbarsState> {
    static snackbar: YoleSnackbars;

    static showText(text: string, duration: number = 3000, messageType?: MessageType, vertical?: "top" | "bottom", horizontal?: "left" | "center" | "right"): void {
        if (YoleSnackbars.snackbar) {
            YoleSnackbars.snackbar.show(text, "loading", duration, messageType, vertical, horizontal);
        }
    }

    static showTextIcon(text: string, icon: string, duration?: number, messageType?: MessageType, vertical?: "top" | "bottom", horizontal?: "left" | "center" | "right"): void {
        if (YoleSnackbars.snackbar) {
            YoleSnackbars.snackbar.show(text, icon, duration, messageType, vertical, horizontal);
        }
    }

    static closeMe(): void {
        if (YoleSnackbars.snackbar) {
            YoleSnackbars.snackbar.closeMe();
        }
    }

    constructor(props: any) {
        super(props);
        this.state = {show: false, duration: 3000};
        this.closeMe = this.closeMe.bind(this);
        this.show = this.show.bind(this);
    }

    componentWillUnmount() {

    }

    show(text: string, icon?: string, duration?: number, messageType?: MessageType, vertical?: "top" | "bottom", horizontal?: "left" | "center" | "right"): void {
        if (this.state.show) {
            this.closeMe();
        }
        this.setState({
            show: true,
            text: text,
            icon: icon,
            duration: duration,
            messageType: messageType,
            vertical: vertical,
            horizontal: horizontal
        });
    }

    closeMe(): void {
        this.setState({show: false, text: "", duration: 3000});
    }

    render() {
        let messageType: MessageType = "info";
        if (this.state.messageType) {
            messageType = this.state.messageType;
        }
        let vertical: "top" | "bottom" = "top";
        if (this.state.vertical) {
            vertical = this.state.vertical;
        } else if (this.props.vertical) {
            vertical = this.props.vertical;
        }
        let horizontal: "left" | "center" | "right" = "center";
        if (this.state.horizontal) {
            horizontal = this.state.horizontal;
        } else if (this.props.horizontal) {
            horizontal = this.props.horizontal;
        }
        const style = this.props.style ? this.props.style : {};
        if (vertical == "bottom") {
            style.bottom = 90;
        }
        if (vertical == "top") {
            style.top = 90;
        }
        return <Snackbar style={style} open={this.state.show}
                         anchorOrigin={{
                             vertical: vertical,
                             horizontal: horizontal,
                         }}
                         autoHideDuration={this.state.duration}
                         onClose={this.closeMe}>
            <Alert severity={messageType} onClose={this.closeMe} elevation={6}
                   variant="filled">{this.state.text}</Alert>
        </Snackbar>
    }
}
