import YwsPayment from "./YwsPayment";

export default class YwsPaymentInitRecord {
    amount: string = "";
    paymentKey: string = "";
    companyPage: string = "";
    currencySymbol: string = "";
    productName: string = "";
    productImage: string = "";
    orderDescription: string = "";
    // DCB 需要的
    mobile: string = "";
    operator: string = "";

    paymentList: Array<YwsPayment> = [];
}
