import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import YoleUtil from "./util/YoleUtil";
import AppClientInfo from "./common/AppClientInfo";
import intl from "react-intl-universal";

//1. 初始化设备
declare function initDevice(deviceReadyFunction: Function): void;

declare function getLanguageText(lang: string, callback: (locales: any) => void): void;

declare function getAppClientInfo(): any;

window.setTimeout(() => {
    initDevice(initApp);
    reportWebVitals()
}, 300);

function initApp() {
    // 合并 setting 到 clientInfo
    const clientInfo = getAppClientInfo();
    AppClientInfo.clientInfo = YoleUtil.mergeObject(new AppClientInfo(), clientInfo);
    // 加载app
    initDefaultTheme();
    loadApp();
}

function loadApp() {
    AppClientInfo.clientInfo.appkey = YoleUtil.getParameterByName("appkey", window.location.href);
    let language = YoleUtil.getParameterByName("lang", window.location.href);
    if (language == null || language.trim().length == 0) {
        language = "en-US";
    }
    getLanguageText(language, (locales) => {
        intl.init({currentLocale: language, locales}).then(() => {
            renderApp();
        });
    });
}

function initDefaultTheme() {

}

function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
