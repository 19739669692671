import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {Button, Paper, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import YoleHeaderBar from "../../component/YoleHeaderBar";
import YoleUtil from "../../util/YoleUtil";
import AjaxHelper from "../../core/AjaxHelper";
import AppClientInfo from "../../common/AppClientInfo";

interface YwsTestThailandPaymentPageProp extends RouteComponentProps {

}

export class YwsTestThailandPaymentPageState {
    msisdn: string = "";
}

export default class YwsTestThailandPaymentPage extends React.Component<YwsTestThailandPaymentPageProp, YwsTestThailandPaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            msisdn: "",
        };
        this.onSendSms = this.onSendSms.bind(this);
    }

    componentDidMount(): void {
        let msisdn = YoleUtil.getParameterByName("msisdn", window.location.href);
        // if (msisdn == null || msisdn.trim().length == 0) {
        //     window.location.href = "https://mis.etracker.cc/THWAP/MsisdnForwarding.aspx?Keyword=LV&Shortcode=4541377&CallBackURL=https://api.kiwitok.com/api/test/callback/81000000";
        // } else {
        //     this.setState({msisdn: msisdn})
        // }
    }

    getMsisdn() {
        // fetch('https://mis.etracker.cc/THWAP/MsisdnForwarding.aspx?Keyword=LV&Shortcode=4541377&CallBackURL=http://localhost:8075/api/test/callback/81000000')
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok ' + response.statusText);
        //         }
        //         return response;
        //     })
        //     .then(data => {
        //         console.log("#####", data)
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     });

        // AjaxHelper.get(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: any, textStatus: string, response: any) => {
        //     HttpService.response(successFunction, data, failFunction);
        // }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography variant={"h5"}>Thailand test</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <Paper>
                    <div style={{padding: 12}}>
                        <Typography variant={"h5"}>Msisdn: {this.state.msisdn}</Typography>
                    </div>
                    <Button variant={"contained"} onClick={this.getMsisdn} color={"primary"}
                            style={{width: "100%"}}>获取手机号</Button>
                </Paper>
                <div style={{height: 12}}/>
                <Paper>
                    <div style={{padding: 12}}>
                        <Button variant={"contained"} onClick={this.onSendSms} color={"primary"}
                                style={{width: "100%"}}>Test send SMS</Button>
                    </div>
                </Paper>
            </div>
        </div>
    }

    onSendSms() {
        const phoneNumber = "4541377";
        const message = "LV";
        window.location.href = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
    }
}
