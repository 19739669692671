import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleToast from "../component/YoleToast";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";
import YwsPayData from "../model/YwsPayData";

interface YwsTestSberPayPageProp extends RouteComponentProps {

}

export class YwsTestSberPayPageState {
    amount: number = 0;
    deviceType: string = "";
}

export default class YwsTestSberPayPage extends React.Component<YwsTestSberPayPageProp, YwsTestSberPayPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            deviceType: "MOBILE",
            amount: 10
        };
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {

    }

    onBack() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: grey[300], borderBottom: "none"}}
                           leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography variant={"h5"}>Sber pay test page</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <div style={{paddingTop: 50, fontSize: 18, fontWeight: 600, textAlign: "center"}}>
                    Pay amount<span style={{paddingLeft: 4, fontSize: 22, color: "red"}}>10RUB</span>
                </div>
                <div style={{paddingTop: 50, textAlign: "center"}}>
                    <Button variant={"contained"}
                            onClick={() => {
                                this.setState({deviceType: "MOBILE"}, this.onPaymentClick);

                            }} color={"primary"} style={{width: "50%", padding: 12, backgroundColor: "#4caf50"}}>
                        For SberPay Mobile
                    </Button>
                </div>
                <div style={{paddingTop: 50, textAlign: "center"}}>
                    <Button variant={"contained"}
                            onClick={() => {
                                this.setState({deviceType: "DESKTOP"}, this.onPaymentClick);
                            }}
                            color={"primary"} style={{width: "50%", padding: 12, backgroundColor: "#4caf50"}}>
                        For SberPay Desktop
                    </Button>
                </div>
            </div>
        </div>
    }

    onPaymentClick() {
        if (this.state.amount == null || this.state.amount <= 0) {
            YoleSnackbars.showText("Please enter payment amount", 10000, "error");
            return;
        } else {
            YoleToast.showToastLoading();
            YwsPaymentV2Service.ifreePay("80000004", this.state.amount.toString(), "2090",
                "sber", this.state.deviceType, (payData: YwsPayData) => {
                    window.location.href = payData.payUrl;
                })
        }
    }
}
