import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleToast from "../component/YoleToast";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";

interface YwsTestPaymentResultsPageProp extends RouteComponentProps<{ billingNumber: string }> {

}

export class YwsTestPaymentResultsPageState {

}

export default class YwsTestPaymentResultsPage extends React.Component<YwsTestPaymentResultsPageProp, YwsTestPaymentResultsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onPaymentSuccess = this.onPaymentSuccess.bind(this);
        this.onPaymentFail = this.onPaymentFail.bind(this);
        this.onPaymentCancel = this.onPaymentCancel.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "white", borderWidth: 0}}
                           leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography>Payment result test</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <div style={{textAlign: "center"}}>
                    <Button variant={"contained"} onClick={this.onPaymentSuccess}
                            style={{
                                width: "80%",
                                marginTop: 16,
                                marginBottom: 16,
                                backgroundColor: "#5F74FF"
                            }}>
                        <Typography style={{color: "white"}}>
                            {"支付成功"}
                        </Typography>
                    </Button>
                    <Button variant={"contained"} onClick={this.onPaymentFail}
                            style={{
                                width: "80%",
                                marginTop: 16,
                                marginBottom: 16,
                                backgroundColor: "#d15353"
                            }}>
                        <Typography style={{color: "white"}}>
                            {"支付失败"}
                        </Typography>
                    </Button>
                    <Button variant={"contained"} onClick={this.onPaymentCancel}
                            style={{
                                width: "80%",
                                marginTop: 16,
                                marginBottom: 16,
                            }}>
                        <Typography>
                            {"取消支付"}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    }

    onPaymentSuccess() {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.testPaymentResults(this.props.match.params.billingNumber, "success", (pageUrl: string) => {
            window.location.replace(pageUrl);
            YoleToast.closeToast();
        });
    }

    onPaymentFail() {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.testPaymentResults(this.props.match.params.billingNumber, "fail", (pageUrl: string) => {
            window.location.replace(pageUrl);
            YoleToast.closeToast();
        });
    }

    onPaymentCancel() {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.testPaymentResults(this.props.match.params.billingNumber, "cancel", (pageUrl: string) => {
            window.location.replace(pageUrl);
            YoleToast.closeToast();
        });
    }
}
