import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography
} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YwsPaymentInitRecord from "../model/YwsPaymentInitRecord";
import YoleToast from "../component/YoleToast";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";
import YwsPayment from "../model/YwsPayment";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPayData from "../model/YwsPayData";
import YoleUtil from "../util/YoleUtil";

interface YwsPaymentInitV2PageProp extends RouteComponentProps<{ id: string }> {

}

export class YwsPaymentInitV2PageState {
    paymentInfo: YwsPaymentInitRecord = new YwsPaymentInitRecord();
}

export default class YwsPaymentInitV2Page extends React.Component<YwsPaymentInitV2PageProp, YwsPaymentInitV2PageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            paymentInfo: new YwsPaymentInitRecord()
        };
        this.onBack = this.onBack.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.getPaymentInit(this.props.match.params.id, (paymentInfo: YwsPaymentInitRecord) => {
            YoleToast.closeToast();
            this.setState({paymentInfo: paymentInfo}, () => {
                if (paymentInfo.paymentList != null && paymentInfo.paymentList.length == 1) {
                    this.state.paymentInfo.paymentKey = paymentInfo.paymentList[0].paymentKey;
                    this.forceUpdate(this.onPaymentClick);
                }
            });
        });
    }

    onBack() {
        if (this.state.paymentInfo.companyPage != null && this.state.paymentInfo.companyPage.trim().length > 0) {
            let companyPage = this.state.paymentInfo.companyPage;
            if (companyPage.indexOf("?") > 0) {
                companyPage += "&billingNumber=&status=unpaid";
            } else {
                companyPage += "?billingNumber=&status=unpaid";
            }
            window.location.replace(companyPage);
        }
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "white", borderWidth: 0}}
                           leftComp={
                               <div style={{width: "20%", textAlign: "left"}}>
                                   <IconButton onClick={this.onBack}>
                                       <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/on_back.jpg"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                   </IconButton>
                               </div>
                           }
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography
                                       style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                                       Payment
                                   </Typography>
                               </div>
                           }/>
            <Paper elevation={0} style={{margin: 8, padding: 8, backgroundColor: "unset"}}>
                <div style={{margin: "auto", textAlign: "center", display: "table", width: "100%"}}>
                    <img src={this.state.paymentInfo.productImage} alt={"Product image"}
                         style={{width: "35%"}}/>
                    <Typography style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                        {this.state.paymentInfo.productName}
                    </Typography>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "flex-end"}}>
                        <Typography style={{
                            backgroundColor: "#E7EBF3",
                            padding: 4,
                            borderRadius: 4,
                            color: "#77797D",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(24) + "px",
                        }}>
                            {this.state.paymentInfo.orderDescription}
                        </Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(34) + "px",
                        }}>{this.state.paymentInfo.currencySymbol}</Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(52),
                            lineHeight: YoleUtil.calculateElementWidth(52) + "px",
                        }}>{this.state.paymentInfo.amount}</Typography>
                    </div>
                </div>
            </Paper>
            <div style={{marginTop: 8, paddingLeft: 12, paddingRight: 12}}>
                <Typography style={{color: "rgba(33, 34, 37, 0.5)", fontSize: YoleUtil.calculateElementWidth(32)}}>
                    Select Payment Method
                </Typography>
                <Paper elevation={0} style={{borderRadius: 8}}>
                    <List style={{marginTop: 6, padding: 0}}>
                        {this.state.paymentInfo.paymentList != null && this.state.paymentInfo.paymentList.length > 0 &&
                        this.state.paymentInfo.paymentList.map((payment: YwsPayment, index: number) => {
                            let selected = this.state.paymentInfo.paymentKey == payment.paymentKey;
                            return <ListItem key={payment.id} button
                                             onClick={() => {
                                                 this.state.paymentInfo.paymentKey = payment.paymentKey;
                                                 this.forceUpdate();
                                             }}
                                             divider={index + 1 < this.state.paymentInfo.paymentList.length}>
                                <ListItemText primary={
                                    <Typography style={{fontWeight: 700, fontSize: YoleUtil.calculateElementWidth(32)}}>
                                        {payment.paymentName}
                                    </Typography>
                                }/>
                                <ListItemSecondaryAction style={{right: 4}}>
                                    <IconButton onClick={() => {
                                        this.state.paymentInfo.paymentKey = payment.paymentKey;
                                        this.forceUpdate();
                                    }}>
                                        <img
                                            src={selected ? "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/selected.png" : "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/not_selected.png"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Paper>
            </div>
            <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 48}}>
                <div style={{textAlign: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
                    <Button variant={"contained"} onClick={this.onPaymentClick}
                            style={{
                                width: "100%",
                                paddingTop: YoleUtil.calculateElementWidth(26),
                                paddingBottom: YoleUtil.calculateElementWidth(26),
                                backgroundColor: "#5F74FF"
                            }}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold", color: "white"}}>
                            {"Pay"}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    }

    onPaymentClick() {
        if (this.state.paymentInfo.paymentKey == null || this.state.paymentInfo.paymentKey.trim().length == 0) {
            YoleSnackbars.showText("Please select payment method", 10000, "error");
            return;
        }
        if (this.state.paymentInfo.paymentKey == "OP_DCB") {
            this.props.history.push("/dcb/paymentV2/" + this.props.match.params.id);
        } else {
            YoleToast.showToastLoading();
            YwsPaymentV2Service.payOrder(this.state.paymentInfo, (payData: YwsPayData) => {
                if (payData.payUrl != null && payData.payUrl.length > 0) {
                    window.location.replace(payData.payUrl);
                } else {
                    YoleToast.closeToast();
                }
            });
        }
    }
}
