import {Button, Paper, TextField, Typography} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleToast from "../component/YoleToast";
import DcbPaymentService from "../service/DcbPaymentService";
import YwOnlinePaymentResult from "../model/YwOnlinePaymentResult";
import YoleSnackbars from "../component/YoleSnackbars";

interface OnlinePaymentPageProp extends RouteComponentProps {

}

export class OnlinePaymentPageState {
    amount?: number;
}

export default class OnlinePaymentPage extends React.Component<OnlinePaymentPageProp, OnlinePaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {

    }

    onPaymentClick() {
        if (this.state.amount && this.state.amount > 0) {
            YoleToast.showToastLoading();
            DcbPaymentService.onlinePayment(+this.state.amount, (result: YwOnlinePaymentResult) => {
                YoleToast.closeToast();
                window.location.href = result.webUrl;
            })
        } else {
            YoleSnackbars.showText("Please enter the amount", 10000, "error");
        }
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", paddingBottom: 112}}>
            <div style={{paddingTop: 112}}/>
            <Paper elevation={0} style={{margin: 8}}>
                <div style={{margin: "auto", textAlign: "center", display: "table"}}>
                    <Typography variant={"h3"}>Payment test page</Typography>
                </div>
                <div style={{marginTop: 16, paddingLeft: 8, paddingRight: 8}}>
                    <TextField label="Amount" variant="outlined" value={this.state.amount} type="number"
                               style={{width: "100%"}}
                               onChange={(event) => {
                                   let value = event.target.value;
                                   if (value != "") {
                                       this.setState({amount: +value})
                                   } else {
                                       this.setState({amount: undefined})
                                   }
                               }}/>
                </div>
            </Paper>
            <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 64, textAlign: "center"}}>
                <Button variant={"contained"} color={"primary"} style={{width: "75%"}} onClick={this.onPaymentClick}>
                    Immediate payment
                </Button>
            </div>
        </div>
    }
}
