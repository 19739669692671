import React from 'react';
import './App.css';
import {HashRouter, Route, Switch} from "react-router-dom";
import {theme} from "./theme/YoleTheme";
import {MuiThemeProvider} from "@material-ui/core";
import YoleToast from "./component/YoleToast";
import YoleSnackbars from "./component/YoleSnackbars";
import OnlinePaymentPage from "./dcb/OnlinePaymentPage";
import OnlinePaymentResultPage from "./dcb/OnlinePaymentResultPage";
import SmsPaymentPage from "./dcb/SmsPaymentPage";
import YoleMessageDialog from "./component/YoleMessageDialog";
import OnlinePaymentRefundPage from "./dcb/OnlinePaymentRefundPage";
import GamePaymentResultPage from "./game/GamePaymentResultPage";
import HomePage from "./home/HomePage";
import YwsPaymentInitV2Page from "./v2payment/YwsPaymentInitV2Page";
import YwsDcbPaymentV2Page from "./v2payment/YwsDcbPaymentV2Page";
import YwsPaymentResultV2Page from "./v2payment/YwsPaymentResultV2Page";
import KiwitokPaymentResultPage from "./kiwitok/KiwitokPaymentResultPage";
import YwsTestPaymentPage from "./v2payment/YwsTestPaymentPage";
import YwsBeelineTestPage from "./v2payment/test/YwsBeelineTestPage";
import YwsTestFastPaymentPage from "./v2payment/YwsTestFastPaymentPage";
import YwsTestPaymentResultsPage from "./v2payment/YwsTestPaymentResultsPage";
import YwsTestThailandPaymentPage from "./v2payment/test/YwsTestThailandPaymentPage";
import YwsTestSberPayPage from "./v2payment/YwsTestSberPayPage";

export default class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {

    }

    render() {
        return <HashRouter>
            <MuiThemeProvider theme={theme}>
                <YoleToast ref={(element: any) => {
                    YoleToast.toast = element;
                }}/>
                <YoleSnackbars ref={(element: any) => {
                    YoleSnackbars.snackbar = element;
                }}/>
                <YoleMessageDialog ref={(element: any) => {
                    YoleMessageDialog.dialog = element;
                }}/>
                <Switch>
                    <Route path="/" component={HomePage} exact={true}/>
                    <Route path="/dcb/payment/:id" component={SmsPaymentPage}/>
                    <Route path="/test/refund" component={OnlinePaymentRefundPage}/>
                    <Route path="/test/online" component={OnlinePaymentPage}/>
                    <Route path="/test/payResult" component={OnlinePaymentResultPage}/>
                    <Route path="/onlinePayResult" component={OnlinePaymentResultPage}/>
                    <Route path="/game/onlinePayResult" component={GamePaymentResultPage}/>
                    <Route path="/init/payment/:id" component={YwsPaymentInitV2Page}/>
                    <Route path="/dcb/paymentV2/:id" component={YwsDcbPaymentV2Page}/>
                    <Route path="/paymentV2/result/:status/:billingNumber?" component={YwsPaymentResultV2Page}/>
                    <Route path="/kiwitok/payment/result" component={KiwitokPaymentResultPage}/>
                    <Route path="/test/paymentV2" component={YwsTestPaymentPage}/>
                    <Route path="/test/beeline" component={YwsBeelineTestPage}/>
                    <Route path="/test/bankCardPayment" component={YwsTestFastPaymentPage}/>
                    <Route path="/test/sberPayment" component={YwsTestSberPayPage}/>
                    <Route path="/test/paymentResults/:billingNumber" component={YwsTestPaymentResultsPage}/>
                    <Route path="/test/thailandSmsPay" component={YwsTestThailandPaymentPage}/>
                </Switch>
            </MuiThemeProvider>
        </HashRouter>
    }
}
