import * as React from "react";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import intl from "react-intl-universal";

export class YoleMessageDialogProps {

}

export class YoleMessageDialogState {
    open: boolean = false;
    content?: string | React.ReactNode;
    leftButtonLabel?: string = intl.get('cancel');
    onLeftButtonClick?: () => void;
    rightButtonLabel?: string = intl.get('ok');
    onRightButtonClick?: () => void;
}

export default class YoleMessageDialog extends React.Component<YoleMessageDialogProps, YoleMessageDialogState> {
    static dialog: YoleMessageDialog;

    constructor(props: any) {
        super(props);
        this.state = {open: false};
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onLeftButtonClick = this.onLeftButtonClick.bind(this);
        this.onRightButtonClick = this.onRightButtonClick.bind(this);
    }

    openDialog(content: string | React.ReactNode, onRightButtonClick?: () => void, onLeftButtonClick?: () => void, rightButtonLabel: string = intl.get('ok'), leftButtonLabel: string = intl.get('cancel')) {
        this.setState({
            open: true,
            content: content,
            rightButtonLabel: rightButtonLabel,
            onRightButtonClick: onRightButtonClick,
            leftButtonLabel: leftButtonLabel,
            onLeftButtonClick: onLeftButtonClick
        });
    }

    closeDialog() {
        this.setState({open: false});
    }

    onLeftButtonClick() {
        this.closeDialog();
        if (this.state.onLeftButtonClick) {
            this.state.onLeftButtonClick();
        }
    }

    onRightButtonClick() {
        this.closeDialog();
        if (this.state.onRightButtonClick) {
            this.state.onRightButtonClick();
        }
    }

    render() {
        const actions: Array<JSX.Element> = [];
        if (this.state.leftButtonLabel != null && this.state.leftButtonLabel.length > 0)
            actions.push(<Button variant={"outlined"}
                                 onClick={this.onLeftButtonClick}
                                 style={{float: "left"}}>{this.state.leftButtonLabel}</Button>);
        if (this.state.rightButtonLabel != null && this.state.rightButtonLabel.length > 0)
            actions.push(<Button variant={"contained"} color={"primary"}
                                 onClick={this.onRightButtonClick}>{this.state.rightButtonLabel}</Button>);
        return (
            <Dialog open={this.state.open}>
                <DialogContent style={{margin: 8}}>
                    {this.state.content}
                </DialogContent>
                <DialogActions style={{display: "flex", justifyContent: "space-between", margin: 8}}>
                    {actions}
                </DialogActions>
            </Dialog>
        );
    }
};
