import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleUtil from "../util/YoleUtil";
import YoleToast from "../component/YoleToast";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";

class YwsTestCountry {
    countryCode: string = "";
    countryName: string = "";

    constructor(countryCode: string, countryName: string) {
        this.countryCode = countryCode;
        this.countryName = countryName;
    }
}

interface YwsTestPaymentPageProp extends RouteComponentProps <{ status: string, billingNumber: string }> {

}

export class YwsTestPaymentPageState {
    amount?: number;
    countryList: Array<YwsTestCountry> = [];
    paymentMethodList: Array<string> = [];
    selectedCountryCode?: string;
    selectedPaymentMethod?: string;
}

export default class YwsTestPaymentPage extends React.Component<YwsTestPaymentPageProp, YwsTestPaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            countryList: [],
            paymentMethodList: [],
        };
        this.onBack = this.onBack.bind(this);
        this.onChangePaymentMethodList = this.onChangePaymentMethodList.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {
        let countryList: Array<YwsTestCountry> = [];
        countryList.push(new YwsTestCountry("RU", "俄罗斯"));
        countryList.push(new YwsTestCountry("US", "其他国家"));
        this.setState({countryList: countryList, selectedCountryCode: "RU"}, this.onChangePaymentMethodList)
    }

    onBack() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "white", borderWidth: 0}}
                           leftComp={
                               <div style={{width: "20%", textAlign: "left"}}>
                                   <IconButton onClick={this.onBack}>
                                       <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/on_back.jpg"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                   </IconButton>
                               </div>
                           }
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography style={{
                                       fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)
                                   }}>Payment test</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <Typography style={{color: "rgba(33, 34, 37, 0.5)", fontSize: YoleUtil.calculateElementWidth(32)}}>
                    Select country
                </Typography>
                <Paper elevation={0} style={{borderRadius: 8}}>
                    <List style={{marginTop: 2, padding: 0}}>
                        {this.state.countryList.map((country: YwsTestCountry, index: number) => {
                            let selected = this.state.selectedCountryCode == country.countryCode;
                            return <ListItem key={country.countryCode}
                                             divider={index + 1 < this.state.countryList.length}>
                                <ListItemText primary={
                                    <Typography style={{fontWeight: 700, fontSize: YoleUtil.calculateElementWidth(32)}}>
                                        {country.countryName}
                                    </Typography>
                                }/>
                                <ListItemSecondaryAction style={{right: 4}}>
                                    <IconButton onClick={() => {
                                        this.setState({selectedCountryCode: country.countryCode}, this.onChangePaymentMethodList)
                                    }}>
                                        <img
                                            src={selected ? "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/selected.png" : "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/not_selected.png"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Paper>
                {this.state.paymentMethodList.length > 0 &&
                <Typography style={{
                    color: "rgba(33, 34, 37, 0.5)",
                    marginTop: 12,
                    fontSize: YoleUtil.calculateElementWidth(32)
                }}>Select payment method</Typography>}
                <Paper elevation={0} style={{borderRadius: 8}}>
                    <List style={{marginTop: 2, padding: 0}}>
                        {this.state.paymentMethodList.map((paymentMethod: string, index: number) => {
                            let selected = this.state.selectedPaymentMethod == paymentMethod;
                            return <ListItem key={paymentMethod}
                                             divider={index + 1 < this.state.paymentMethodList.length}>
                                <ListItemText primary={
                                    <Typography style={{fontWeight: 700, fontSize: YoleUtil.calculateElementWidth(32)}}>
                                        {paymentMethod}
                                    </Typography>
                                }/>
                                <ListItemSecondaryAction style={{right: 4}}>
                                    <IconButton onClick={() => {
                                        this.setState({selectedPaymentMethod: paymentMethod})
                                    }}>
                                        <img
                                            src={selected ? "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/selected.png" : "https://yolewallet.oss-accelerate.aliyuncs.com/web/image/not_selected.png"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                </Paper>
                <Typography style={{
                    color: "rgba(33, 34, 37, 0.5)", marginTop: 12,
                    fontSize: YoleUtil.calculateElementWidth(32)
                }}>Payment amount</Typography>
                <TextField label="Test amount" variant="outlined"
                           value={this.state.amount} type="number"
                           style={{width: "100%", backgroundColor: "white"}}
                           onChange={(event) => {
                               let value = event.target.value;
                               if (value != "") {
                                   this.setState({amount: +value})
                               } else {
                                   this.setState({amount: undefined})
                               }
                           }}/>
                <div style={{textAlign: "center", marginTop: 20}}>
                    <Button variant={"contained"} onClick={this.onPaymentClick}
                            style={{
                                width: "100%",
                                paddingTop: YoleUtil.calculateElementWidth(26),
                                paddingBottom: YoleUtil.calculateElementWidth(26),
                                backgroundColor: "#5F74FF"
                            }}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold", color: "white"}}>
                            {"Pay"}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    }

    onChangePaymentMethodList() {
        let paymentMethodList: Array<string> = [];
        if (this.state.selectedCountryCode == "RU") {
            paymentMethodList.push("QIWI_WALLET");
            paymentMethodList.push("IFREE");
        } else if (this.state.selectedCountryCode == "US") {
            paymentMethodList.push("PNC");
        }
        if (paymentMethodList.length > 0) {
            this.setState({
                paymentMethodList: paymentMethodList,
                selectedPaymentMethod: paymentMethodList[0]
            })
        }
    }

    onPaymentClick() {
        if (this.state.selectedCountryCode == null) {
            YoleSnackbars.showText("Please select country", 10000, "error");
            return;
        }
        if (this.state.amount == null || this.state.amount <= 0) {
            YoleSnackbars.showText("Please enter payment amount", 10000, "error");
            return;
        } else {
            YoleToast.showToastLoading();
            YwsPaymentV2Service.testPayment(this.state.selectedCountryCode, this.state.amount,
                this.state.selectedPaymentMethod + "", (url: string) => {
                    window.location.href = url;
                })
        }
    }
}
