import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleUtil from "../util/YoleUtil";
import YoleToast from "../component/YoleToast";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";
import YwsPayData from "../model/YwsPayData";

interface YwsTestFastPaymentPageProp extends RouteComponentProps {

}

export class YwsTestFastPaymentPageState {
    appKey: string = "";
    paymentMethod: string = "";
    projectId: string = "";
    deviceType: string = "";
    amount?: number;
}

export default class YwsTestFastPaymentPage extends React.Component<YwsTestFastPaymentPageProp, YwsTestFastPaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            appKey: "",
            paymentMethod: "bankCard",
            deviceType: "MOBILE",
            projectId: "2090",
            amount: 10
        };
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {
        const href = window.location.href;
        let appKey = YoleUtil.getParameterByName("appKey", href);
        this.setState({appKey: appKey});
    }

    onBack() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography variant={"h5"}>Payment test</Typography>
                               </div>
                           }/>
            <div style={{padding: 12}}>
                <Typography style={{color: "rgba(0, 0, 0, 0.54)"}}>Project id</Typography>
                <TextField variant="outlined" value={this.state.projectId}
                           style={{width: "100%", backgroundColor: "white", marginTop: 8}}
                           onChange={(event) => {
                               let value = event.target.value;
                               this.setState({projectId: value})
                           }}/>
                <div style={{height: 40}}/>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Payment method</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={this.state.paymentMethod}
                                onChange={(event, value) => {
                                    this.setState({paymentMethod: value})
                                }}>
                        <FormControlLabel value="bankCard" control={<Radio color={"primary"}/>} label="银行卡,电子钱包支付"/>
                        <FormControlLabel value="sbp" control={<Radio color={"primary"}/>} label="Sbp 支付"/>
                        <FormControlLabel value="sber" control={<Radio color={"primary"}/>} label="Sber 支付"/>
                    </RadioGroup>
                </FormControl>
                <div style={{width: "100%"}}/>
                {this.state.paymentMethod == "sber" &&
                <FormControl component="fieldset" style={{marginTop: 8}}>
                    <FormLabel component="legend">Device type</FormLabel>
                    <RadioGroup aria-label="gender" name="gender1" value={this.state.deviceType}
                                onChange={(event, value) => {
                                    this.setState({deviceType: value})
                                }}>
                        <FormControlLabel value="MOBILE" control={<Radio/>} label="Mobile"/>
                        <FormControlLabel value="DESKTOP" control={<Radio/>} label="Desktop"/>
                    </RadioGroup>
                </FormControl>}
                <div style={{height: 36}}/>
                <Typography style={{color: "rgba(0, 0, 0, 0.54)"}}>Payment amount</Typography>
                <TextField variant="outlined" value={this.state.amount} type="number"
                           style={{width: "100%", backgroundColor: "white", marginTop: 8}}
                           onChange={(event) => {
                               let value = event.target.value;
                               if (value != "") {
                                   this.setState({amount: +value})
                               } else {
                                   this.setState({amount: undefined})
                               }
                           }}/>
                <div style={{textAlign: "center", marginTop: 20}}>
                    <Button variant={"contained"} onClick={this.onPaymentClick} color={"primary"}
                            style={{width: "100%"}}>
                        Pay
                    </Button>
                </div>
            </div>
        </div>
    }

    onPaymentClick() {
        if (this.state.amount == null || this.state.amount <= 0) {
            YoleSnackbars.showText("Please enter payment amount", 10000, "error");
            return;
        } else {
            YoleToast.showToastLoading();
            let appKey = this.state.appKey;
            if (appKey == null || appKey.trim().length == 0) {
                appKey = "81000004";
            }
            let deviceType = this.state.paymentMethod == "sber" ? this.state.deviceType : "";
            YwsPaymentV2Service.ifreePay(appKey, this.state.amount + "", this.state.projectId,
                this.state.paymentMethod, deviceType, (payData: YwsPayData) => {
                    window.location.href = payData.payUrl;
                })
        }
    }
}
