import React from "react";
import {Button, IconButton, InputBase, Paper, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import {grey} from "@material-ui/core/colors";
import intl from "react-intl-universal";
import YwsPaymentInitRecord from "../../model/YwsPaymentInitRecord";
import YoleToast from "../../component/YoleToast";
import YwsPaymentV2Service from "../../service/YwsPaymentV2Service";
import {EOperator} from "../../model/DcbOperator";
import YoleHeaderBar from "../../component/YoleHeaderBar";
import YoleUtil from "../../util/YoleUtil";
import YoleSnackbars from "../../component/YoleSnackbars";
import YwsPayData from "../../model/YwsPayData";
import * as animationData from "../json/loading2.json";
import Lottie from 'react-lottie';
import {CheckCircleOutlineOutlined, HighlightOff} from "@material-ui/icons";
import YwDcbPaymentStatus from "../../model/YwDcbPaymentStatus";

interface YwsBeelineTestPageProp extends RouteComponentProps {

}

export class YwsBeelineTestPageState {
    paymentInfo: YwsPaymentInitRecord = new YwsPaymentInitRecord();
    lottieOption: any = {};
    billingNumber: string = "";
    paymentStatus: string = "";
    intervalId?: number;
    errorMassage?: string;
}

export default class YwsBeelineTestPage extends React.Component<YwsBeelineTestPageProp, YwsBeelineTestPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            paymentInfo: new YwsPaymentInitRecord(),
            lottieOption: {},
            billingNumber: "",
            paymentStatus: ""
        };
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.getPaymentStatus = this.getPaymentStatus.bind(this);
    }

    componentDidMount(): void {
        const paymentInfo: YwsPaymentInitRecord = new YwsPaymentInitRecord();
        paymentInfo.paymentKey = "OP_DCB";
        paymentInfo.amount = "10";
        paymentInfo.currencySymbol = "RUB";
        paymentInfo.orderDescription = "Beeline payment test";
        paymentInfo.productImage = "https://yolewallet.oss-ap-southeast-1.aliyuncs.com/product/2023-10-07/ce017fa270684bc181dc67c9e733084d.jpg";
        paymentInfo.productName = "Спутниковая карта - 3D Земля";
        paymentInfo.operator = "BEELINE";
        // 设置 loading 图标
        const lottieOption = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        this.setState({paymentInfo: paymentInfo, lottieOption: lottieOption});
    }

    componentWillUnmount() {
        window.clearInterval(this.state.intervalId);
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "#5F74FF", borderWidth: 0}}
                           leftComp={<div style={{width: "20%", textAlign: "left"}}/>}
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography
                                       style={{
                                           fontWeight: "bold",
                                           fontSize: YoleUtil.calculateElementWidth(34),
                                           color: "white"
                                       }}>
                                       Beeline payment test
                                   </Typography>
                               </div>
                           }/>
            <Paper elevation={0} style={{margin: 8, padding: 8, backgroundColor: "unset"}}>
                <div style={{margin: "auto", textAlign: "center", display: "table", width: "100%"}}>
                    <img src={this.state.paymentInfo.productImage} alt={"Product image"}
                         style={{width: "35%"}}/>
                    <Typography style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                        {this.state.paymentInfo.productName}
                    </Typography>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "flex-end"}}>
                        <Typography style={{
                            backgroundColor: "#E7EBF3",
                            padding: 4,
                            borderRadius: 4,
                            color: "#77797D",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(24) + "px",
                        }}>
                            {this.state.paymentInfo.orderDescription}
                        </Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(34) + "px",
                        }}>{this.state.paymentInfo.currencySymbol}</Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(52),
                            lineHeight: YoleUtil.calculateElementWidth(52) + "px",
                        }}>{this.state.paymentInfo.amount}</Typography>
                    </div>
                </div>
            </Paper>
            {this.getBodySection()}
        </div>
    }

    getBodySection() {
        if (this.state.paymentStatus == "processing") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <Lottie options={this.state.lottieOption} height={80}/>
                <Typography variant={"h5"}>
                    {intl.get('payProcessing')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "paid") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <IconButton disabled={true}>
                    <CheckCircleOutlineOutlined style={{width: 120, height: 120, color: "#00c853"}}/>
                </IconButton>
                <Typography variant={"h5"}>
                    {intl.get('paySuccess')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "error") {
            return <div style={{textAlign: "center"}}>
                <IconButton disabled={true}>
                    <HighlightOff style={{width: 120, height: 120, color: "red"}}/>
                </IconButton>
                <div style={{width: "100%"}}/>
                <Typography variant={"h4"}>
                    {this.state.errorMassage}
                </Typography>
            </div>
        } else {
            return <div>
                <div>
                    <div style={{marginTop: 16, paddingLeft: 12, paddingRight: 12}}>
                        <Typography variant={"caption"}>
                            {intl.get('validPhoneNumber')}
                        </Typography>
                        <div style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: 4,
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <div style={{
                                width: "20%", textAlign: "center", borderRight: "1px solid rgba(0, 0, 0, 0.23)",
                                paddingTop: 6, paddingBottom: 8
                            }}>+7
                            </div>
                            <div style={{
                                width: "80%", paddingLeft: 8
                            }}>
                                <InputBase id={"benefitNo"} style={{width: "100%"}}
                                           placeholder={"9XXXXXXXXX"}
                                           value={this.state.paymentInfo.mobile}
                                           type="number"
                                           onChange={(event) => {
                                               this.state.paymentInfo.mobile = event.target.value;
                                               this.forceUpdate();
                                           }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{textAlign: "center", marginTop: 20, paddingLeft: 12, paddingRight: 12}}>
                    <Button variant={"contained"} color={"primary"}
                            style={{width: "100%"}}
                            onClick={this.onPaymentClick}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold"}}>
                            {intl.get('payment')}
                        </Typography>
                    </Button>
                </div>
            </div>
        }
    }

    onPaymentClick() {
        const mobile = this.checkMobile();
        if (mobile.length > 0) {
            YoleToast.showToastLoading();
            YwsPaymentV2Service.checkCustomerMobile(mobile, (operator: EOperator) => {
                YoleToast.closeToast();
                if (operator != null && operator == EOperator.BEELINE) {
                    this.createInvoice(mobile);
                } else {
                    YoleSnackbars.showText(intl.get('supported'), 10000, "error");
                }
            })
        }
    }

    createInvoice(mobile: string) {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.testBeelinePay(mobile, this.state.paymentInfo.amount, (payData: YwsPayData) => {
            YoleToast.closeToast();
            this.setState({paymentStatus: "processing", billingNumber: payData.billingNumber}, this.getPaymentStatus)
        });
    }

    getPaymentStatus() {
        const intervalId = window.setInterval(() => {
            YwsPaymentV2Service.getPaymentStatus(this.state.billingNumber, (paymentStatus: YwDcbPaymentStatus) => {
                if (paymentStatus.paymentStatus == "SUCCESSFUL") {
                    this.setState({paymentStatus: "paid"})
                    window.clearInterval(this.state.intervalId);
                } else if (paymentStatus.paymentStatus == "FAILED") {
                    if (paymentStatus.errorMassage == "PAYMENT_CHANNEL_NOT_ENOUGH_MONEY") {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError2')})
                    } else {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError1')})
                    }
                    window.clearInterval(this.state.intervalId);
                } else if (paymentStatus.paymentStatus == "PROCESSING") {
                    this.setState({paymentStatus: "processing"})
                }
            });
        }, 10000);
        this.setState({intervalId: intervalId});
    }

    checkMobile(): string {
        const regexp = new RegExp("^(\\+7|7|8)?9([0-9]){9}$");
        let mobile: string;
        if (this.state.paymentInfo.mobile.startsWith("+79")) {
            mobile = this.state.paymentInfo.mobile;
        } else if (this.state.paymentInfo.mobile.startsWith("79")) {
            mobile = "+" + this.state.paymentInfo.mobile;
        } else if (this.state.paymentInfo.mobile.startsWith("89")) {
            mobile = "+" + this.state.paymentInfo.mobile;
        } else {
            mobile = "+7" + this.state.paymentInfo.mobile;
        }
        const test = regexp.test(mobile);
        if (!test) {
            YoleSnackbars.showText(intl.get('formatError'), 10000, "error");
            return "";
        }
        return mobile;
    }
}
