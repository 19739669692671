import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleToast from "../component/YoleToast";
import YoleUtil from "../util/YoleUtil";

interface OnlinePaymentResultPageProp extends RouteComponentProps {

}

export class OnlinePaymentResultPageState {
    message: string = "";
}

export default class OnlinePaymentResultPage extends React.Component<OnlinePaymentResultPageProp, OnlinePaymentResultPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            message: ""
        };
    }

    componentDidMount(): void {
        YoleToast.showToastLoading();
        const billingNumber = YoleUtil.getParameterByName("merchantOrderId", window.location.href);
        const invoiceId = YoleUtil.getParameterByName("invoiceId", window.location.href);
        const success = YoleUtil.getParameterByName("success", window.location.href);
        window.location.href = "https://api.yolesdk.com/v2/api/redirect/dcbPayResult?invoiceId=" +
            invoiceId + "&merchantOrderId=" + billingNumber + "&success=" + success;

        // YwsPaymentV2Service.redirect(billingNumber)
        // YoleToast.showToastLoading();
        // const billingNumber = YoleUtil.getParameterByName("merchantOrderId", window.location.href);
        // DcbPaymentService.getCompanyPage(billingNumber, (companyPage: string) => {
        //     YoleToast.closeToast();
        //     if (companyPage != null && companyPage.trim().length > 0) {
        //         if (companyPage.indexOf("?") > 0) {
        //             companyPage += "&billingNumber=" + billingNumber;
        //         } else {
        //             companyPage += "?billingNumber=" + billingNumber;
        //         }
        //         window.location.href = companyPage;
        //     } else {
        //         this.setState({message: "Company page error."})
        //     }
        // })
    }

    render() {
        return null;
        // return <div style={{maxWidth: 580, margin: "auto", paddingBottom: 112}}>
        //     <Paper elevation={0} style={{margin: 8, paddingTop: 64}}>
        //         <div style={{margin: "auto", textAlign: "center", display: "table"}}>
        //             <Typography variant={"h3"}>{this.state.message}</Typography>
        //         </div>
        //     </Paper>
        // </div>
    }
}
