import {Button, IconButton, InputBase, Paper, Typography} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YwsPaymentInitRecord from "../model/YwsPaymentInitRecord";
import YoleToast from "../component/YoleToast";
import {EOperator} from "../model/DcbOperator";
import YwsPaymentV2Service from "../service/YwsPaymentV2Service";
import intl from "react-intl-universal";
import YoleSnackbars from "../component/YoleSnackbars";
import YwsPayData from "../model/YwsPayData";
import YoleUtil from "../util/YoleUtil";
import YwDcbPaymentStatus from "../model/YwDcbPaymentStatus";
import Lottie from "react-lottie";
import {CheckCircleOutlineOutlined, HighlightOff} from "@material-ui/icons";
import * as animationData from "./json/loading2.json";

interface YwsDcbPaymentV2PageProp extends RouteComponentProps<{ id: string }> {

}

export class YwsDcbPaymentV2PageState {
    paymentInfo: YwsPaymentInitRecord = new YwsPaymentInitRecord();
    lottieOption: any = {};
    billingNumber: string = "";
    paymentStatus: string = "";
    intervalId?: number;
    errorMassage?: string;
}

export default class YwsDcbPaymentV2Page extends React.Component<YwsDcbPaymentV2PageProp, YwsDcbPaymentV2PageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            paymentInfo: new YwsPaymentInitRecord(),
            lottieOption: {},
            billingNumber: "",
            paymentStatus: ""
        };
        this.onBack = this.onBack.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.getPaymentStatus = this.getPaymentStatus.bind(this);
    }

    componentDidMount(): void {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.getPaymentInit(this.props.match.params.id, (paymentInfo: YwsPaymentInitRecord) => {
            YoleToast.closeToast();
            paymentInfo.paymentKey = "OP_DCB";
            this.setState({paymentInfo: paymentInfo});
        });
        // 设置 loading 图标
        const lottieOption = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        this.setState({lottieOption: lottieOption});
    }

    componentWillUnmount() {
        window.clearInterval(this.state.intervalId);
    }

    onBack() {
        if (this.state.paymentStatus == "processing" || this.state.paymentStatus == "paid" || this.state.paymentStatus == "error") {
            let companyPage = this.state.paymentInfo.companyPage;
            if (companyPage != null) {
                if (companyPage.indexOf("?") > 0) {
                    companyPage += "&billingNumber=" + this.state.billingNumber + "&status=" + this.state.paymentStatus;
                } else {
                    companyPage += "?billingNumber=" + this.state.billingNumber + "&status=" + this.state.paymentStatus;
                }
                YwsPaymentV2Service.userCancel(this.state.billingNumber, () => {

                })
                window.location.replace(companyPage);
            }
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "white", borderWidth: 0}}
                           leftComp={
                               <div style={{width: "20%", textAlign: "left"}}>
                                   <IconButton onClick={this.onBack}>
                                       <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/on_back.jpg"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                   </IconButton>
                               </div>
                           }
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography
                                       style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                                       Payment
                                   </Typography>
                               </div>
                           }/>
            <Paper elevation={0} style={{margin: 8, padding: 8, backgroundColor: "unset"}}>
                <div style={{margin: "auto", textAlign: "center", display: "table", width: "100%"}}>
                    <img src={this.state.paymentInfo.productImage} alt={"Product image"}
                         style={{width: "35%"}}/>
                    <Typography style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                        {this.state.paymentInfo.productName}
                    </Typography>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "flex-end"}}>
                        <Typography style={{
                            backgroundColor: "#E7EBF3",
                            padding: 4,
                            borderRadius: 4,
                            color: "#77797D",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(24) + "px",
                        }}>
                            {this.state.paymentInfo.orderDescription}
                        </Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(28),
                            lineHeight: YoleUtil.calculateElementWidth(34) + "px",
                        }}>{this.state.paymentInfo.currencySymbol}</Typography>
                        <Typography style={{
                            paddingLeft: 4,
                            fontWeight: "bold",
                            fontSize: YoleUtil.calculateElementWidth(52),
                            lineHeight: YoleUtil.calculateElementWidth(52) + "px",
                        }}>{this.state.paymentInfo.amount}</Typography>
                    </div>
                </div>
            </Paper>
            {this.getBodySection()}
            {this.getButtonSection()}
        </div>
    }

    getBodySection() {
        if (this.state.paymentStatus == "processing") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <Lottie options={this.state.lottieOption} height={80}/>
                <Typography variant={"h5"}>
                    {intl.get('payProcessing')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "paid") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <IconButton disabled={true}>
                    <CheckCircleOutlineOutlined style={{width: 120, height: 120, color: "#00c853"}}/>
                </IconButton>
                <Typography variant={"h5"}>
                    {intl.get('paySuccess')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "error") {
            return <div style={{textAlign: "center"}}>
                <IconButton disabled={true}>
                    <HighlightOff style={{width: 120, height: 120, color: "red"}}/>
                </IconButton>
                <div style={{width: "100%"}}/>
                <Typography variant={"h4"}>
                    {this.state.errorMassage}
                </Typography>
            </div>
        } else {
            return <div>
                <div style={{marginTop: 16, paddingLeft: 12, paddingRight: 12}}>
                    <Typography variant={"caption"}>
                        {intl.get('validPhoneNumber')}
                    </Typography>
                    <div style={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: 4,
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <div style={{
                            width: "20%", textAlign: "center", borderRight: "1px solid rgba(0, 0, 0, 0.23)",
                            paddingTop: 6, paddingBottom: 8
                        }}>+7
                        </div>
                        <div style={{
                            width: "80%", paddingLeft: 8
                        }}>
                            <InputBase id={"benefitNo"} style={{width: "100%"}}
                                       placeholder={"9XXXXXXXXX"}
                                       value={this.state.paymentInfo.mobile}
                                       type="number"
                                       onChange={(event) => {
                                           this.state.paymentInfo.mobile = event.target.value;
                                           this.forceUpdate();
                                       }}/>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 16, paddingLeft: 12, paddingRight: 12}}>
                    <Typography variant={"caption"}>
                        {intl.get('supportOperators')}
                    </Typography>
                    <div style={{display: "flex", width: "100%", marginTop: 4, alignItems: "center"}}>
                        <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/megafon_icon.png"}
                             style={{width: 80}}/>
                        <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/yota_icon.png"}
                             style={{width: 80, marginLeft: 8}}/>
                        <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/beeline_icon.png"}
                             style={{width: 80, marginLeft: 8}}/>
                    </div>
                </div>
            </div>
        }
    }

    onPaymentClick() {
        const mobile = this.checkMobile();
        if (mobile.length > 0) {
            YoleToast.showToastLoading();
            YwsPaymentV2Service.checkCustomerMobile(mobile, (operator: EOperator) => {
                YoleToast.closeToast();
                if (operator != null) {
                    this.state.paymentInfo.operator = operator;
                    this.state.paymentInfo.mobile = mobile;
                    this.forceUpdate(this.createInvoice);
                } else {
                    // 提示错误
                    YoleSnackbars.showText(intl.get('supported'), 10000, "error");
                }
            })
        } else {
            YoleSnackbars.showText(intl.get('formatError'), 10000, "error");
        }
    }

    createInvoice() {
        YoleToast.showToastLoading();
        YwsPaymentV2Service.payOrder(this.state.paymentInfo, (payData: YwsPayData) => {
            YoleToast.closeToast();
            this.setState({paymentStatus: "processing", billingNumber: payData.billingNumber}, this.getPaymentStatus)
        });
    }

    checkMobile(): string {
        const regexp = new RegExp("^(\\+7|7|8)?9([0-9]){9}$");
        let mobile: string;
        if (this.state.paymentInfo.mobile.startsWith("+79")) {
            mobile = this.state.paymentInfo.mobile;
        } else if (this.state.paymentInfo.mobile.startsWith("79")) {
            mobile = "+" + this.state.paymentInfo.mobile;
        } else if (this.state.paymentInfo.mobile.startsWith("89")) {
            mobile = "+" + this.state.paymentInfo.mobile;
        } else {
            mobile = "+7" + this.state.paymentInfo.mobile;
        }
        const test = regexp.test(mobile);
        return test ? mobile : "";
    }

    getButtonSection() {
        if (this.state.paymentStatus == "processing" || this.state.paymentStatus == "paid" || this.state.paymentStatus == "error") {
            return <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 48}}>
                <div style={{textAlign: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
                    <Button variant={"contained"}
                            style={{
                                width: "100%",
                                paddingTop: YoleUtil.calculateElementWidth(26),
                                paddingBottom: YoleUtil.calculateElementWidth(26),
                                backgroundColor: "#5F74FF"
                            }}
                            onClick={this.onBack}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold", color: "white"}}>
                            {"Return to home page"}
                        </Typography>
                    </Button>
                </div>
            </div>
        } else {
            return <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 48}}>
                <div style={{textAlign: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20}}>
                    <Button variant={"contained"}
                            style={{
                                width: "100%",
                                paddingTop: YoleUtil.calculateElementWidth(26),
                                paddingBottom: YoleUtil.calculateElementWidth(26),
                                backgroundColor: "#5F74FF"
                            }}
                            onClick={this.onPaymentClick}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold", color: "white"}}>
                            {intl.get('payment')}
                        </Typography>
                    </Button>
                </div>
            </div>
        }
    }

    getPaymentStatus() {
        const intervalId = window.setInterval(() => {
            YwsPaymentV2Service.getPaymentStatus(this.state.billingNumber, (paymentStatus: YwDcbPaymentStatus) => {
                if (paymentStatus.paymentStatus == "SUCCESSFUL") {
                    this.setState({paymentStatus: "paid"})
                    window.clearInterval(this.state.intervalId);
                } else if (paymentStatus.paymentStatus == "FAILED") {
                    if (paymentStatus.errorMassage == "PAYMENT_CHANNEL_NOT_ENOUGH_MONEY") {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError2')})
                    } else {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError1')})
                    }
                    window.clearInterval(this.state.intervalId);
                } else if (paymentStatus.paymentStatus == "PROCESSING") {
                    this.setState({paymentStatus: "processing"})
                }
            });
        }, 10000);
        this.setState({intervalId: intervalId});
    }
}
