import React from "react";
import {RouteComponentProps} from "react-router-dom";

interface HomePageProp extends RouteComponentProps {

}

export class HomePageState {

}

declare function closeH5Web(status: string, billingNumber: string): void;

export default class HomePage extends React.Component<HomePageProp, HomePageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {

    }

    render() {
        return <div style={{maxWidth: 580, textAlign: "center"}}>
            {/*<Button color={"primary"} variant={"contained"} style={{marginTop: 32, width: "50%"}}*/}
            {/*        onClick={()=>{*/}
            {/*            closeH5Web("PAID", "billingNumber");*/}
            {/*        }}>关闭</Button>*/}
            {/*<div style={{width: "100%"}}/>*/}
        </div>
    }
}
