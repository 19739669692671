import _ from "lodash";
import AppClientInfo from "../common/AppClientInfo";

export default class YoleUtil {

    static mergeObject(targetObject: any, object2?: any, ...objectN: any[]): any {
        return _.merge({}, targetObject, object2, ...objectN);
    }

    static stringToArray(source: string, delimiter: string = ",") {
        let list: Array<string> = [];
        if (source != null) {
            list = source.split(delimiter);
        }
        if (list != null && list.length > 0 && list[list.length - 1] == "") {
            list.pop();
        }
        return list;
    }

    static getParameterByName(name: string, url: string): string {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#/]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return "";
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static randomString(len: number | 32) {
        const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        let maxPos = chars.length;
        let random = '';
        for (let i = 0; i < len; i++) {
            random += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return random;
    }

    static decodeBase64(input: string) {
        const _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output += String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output += String.fromCharCode(chr3);
            }
        }
        return output;
    }

    static calculateElementWidth(sourceWidth: number): number {
        // let availWidth = AppClientInfo.clientInfo.availWidth;
        // if (availWidth > 750) {
        //     return sourceWidth;
        // }
        let availWidth = AppClientInfo.clientInfo.availWidth > 580 ? 580 : AppClientInfo.clientInfo.availWidth;
        let sWidth = (Math.floor(sourceWidth / 75 * 100) / 100).toFixed(2);
        let mWidth = availWidth / 10;
        let result = Math.floor(+sWidth * mWidth).toFixed(0);
        return +result;
    }
}
