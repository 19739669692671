export enum EResultStatus {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export default class ApiResponse {
    errorCode?: string;
    message?: string;
    content: any;
    status?: EResultStatus;
}
