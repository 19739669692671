import HttpService from "./HttpService";
import YwDcbPaymentRecord from "../model/YwDcbPaymentRecord";
import YwDcbPaymentResult from "../model/YwDcbPaymentResult";
import YwOnlinePaymentResult from "../model/YwOnlinePaymentResult";
import {EOperator} from "../model/DcbOperator";
import YwDcbPaymentStatus from "../model/YwDcbPaymentStatus";

export default class DcbPaymentService extends HttpService {

    static getDcbPaymentInfo(id: string, successFunction: (result: YwDcbPaymentRecord) => void) {
        const uri = "/api/RUPayment/getDcbPaymentInfo";
        HttpService.get(uri, {id: id}, successFunction, HttpService.handleError);
    }

    static createDCBInvoiceByH5(id: string, mobile: string, operator: EOperator, successFunction: (result: YwDcbPaymentResult) => void) {
        const uri = "/api/RUPayment/createDCBInvoiceByH5";
        HttpService.postFormData(uri, {
            id: id,
            mobile: mobile,
            operater: operator
        }, successFunction, HttpService.handleError);
    }

    static cancelDCBInvoice(billingNumber: string, successFunction: () => void) {
        const uri = "/api/RUPayment/cancelDCBInvoice";
        HttpService.get(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }

    static getPaymentStatus(billingNumber: string, successFunction: (result: YwDcbPaymentStatus) => void) {
        const uri = "/api/RUPayment/getPaymentStatus";
        HttpService.get(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }

    static onlinePayment(amount: number, successFunction: (result: YwOnlinePaymentResult) => void) {
        const uri = "/api/RUPayment/onlinePayment";
        HttpService.get(uri, {amount: amount}, successFunction, HttpService.handleError);
    }

    static refund(billingNumber: string, successFunction: (result: string) => void) {
        const uri = "/api/RUPayment/transaction/refund";
        HttpService.postFormData(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }

    static checkCustomerMobile(mobile: string, successFunction: (result: EOperator) => void) {
        const uri = "/api/RUPayment/checkCustomerMobile";
        HttpService.get(uri, {mobile: mobile}, successFunction, HttpService.handleError);
    }

    static getCompanyPage(billingNumber: string, successFunction: (companyPage: string) => void) {
        const uri = "/api/RUPayment/online/getCompanyPage";
        HttpService.get(uri, {billingNumber: billingNumber}, successFunction, HttpService.handleError);
    }
}
