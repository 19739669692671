import * as React from "react";
import {CSSProperties, HTMLAttributes, ReactNode} from "react";
import YoleUtil from "../util/YoleUtil";

export interface YoleHeaderBarProp extends HTMLAttributes<HTMLDivElement> {
    leftComp?: ReactNode;
    centerComp?: ReactNode;
    rightComp?: ReactNode;
}

export default class YoleHeaderBar extends React.Component<YoleHeaderBarProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles: CSSProperties = {
            width: "100%",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            borderBottom: "1px solid rgb(189, 189, 189)",
            height: 47,
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            zIndex: 99999
        };
        if (this.props.style) {
            styles = YoleUtil.mergeObject(styles, this.props.style);
        }
        return <div style={styles}>
            {this.props.leftComp ? this.props.leftComp : <div style={{width: "20%"}}/>}
            {this.props.centerComp ? this.props.centerComp : <div style={{width: "60%"}}/>}
            {this.props.rightComp ? this.props.rightComp : <div style={{width: "20%"}}/>}
        </div>
    }
}
