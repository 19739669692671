import {
    Button,
    CardMedia,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Paper,
    Radio,
    Typography
} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {ArrowBackIos, CheckCircleOutlineOutlined, HighlightOff} from "@material-ui/icons";
import DcbPaymentService from "../service/DcbPaymentService";
import YoleToast from "../component/YoleToast";
import YwDcbPaymentRecord from "../model/YwDcbPaymentRecord";
import intl from "react-intl-universal";
import YoleSnackbars from "../component/YoleSnackbars";
import YoleUtil from "../util/YoleUtil";
import YwDcbPaymentResult from "../model/YwDcbPaymentResult";
import {grey} from "@material-ui/core/colors";
import {EOperator} from "../model/DcbOperator";
import * as animationData from "./json/loading2.json";
import Lottie from 'react-lottie';
import YwDcbPaymentStatus from "../model/YwDcbPaymentStatus";
import YoleMessageDialog from "../component/YoleMessageDialog";


declare function closeH5Web(status: string, billingNumber: string): void;

declare function nativeClose(callback: (data: any) => void): void;

interface SmsPaymentPageProp extends RouteComponentProps<{ id: string }> {

}

export class SmsPaymentPageState {
    mobile: string = "";
    mobileText: string = "";
    changeMobile: boolean = true;
    billingNumber: string = "";
    paymentStatus: "paid" | "unpaid" | "processing" | "unsupported" | "error" = "unpaid";
    errorMassage?: string;
    paymentInfo: YwDcbPaymentRecord = new YwDcbPaymentRecord();
    checked: boolean = false;
    showVerify: boolean = false;
    verifyCode: string = "";
    intervalId?: number;
    intervalId2?: number;
    second: number = 0;
    lottieOption: any = {};
    operator: EOperator = EOperator.UNDEFINED;
}

export default class SmsPaymentPage extends React.Component<SmsPaymentPageProp, SmsPaymentPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            mobile: "",
            mobileText: "",
            changeMobile: false,
            billingNumber: "",
            paymentStatus: "unpaid",
            paymentInfo: new YwDcbPaymentRecord(),
            checked: false,
            showVerify: false,
            verifyCode: "",
            second: 0,
            lottieOption: {},
            operator: EOperator.UNDEFINED
        };

        this.onBack = this.onBack.bind(this);
        this.onBackCheck = this.onBackCheck.bind(this);
        this.onPaymentClick = this.onPaymentClick.bind(this);
        this.getMobileSection = this.getMobileSection.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.createInvoice = this.createInvoice.bind(this);
        this.onSendSms = this.onSendSms.bind(this);
        this.getPaymentStatus = this.getPaymentStatus.bind(this);
    }

    componentDidMount(): void {
        YoleToast.showToastLoading();
        DcbPaymentService.getDcbPaymentInfo(this.props.match.params.id, (paymentInfo: YwDcbPaymentRecord) => {
            YoleToast.closeToast();
            this.setState({paymentInfo: paymentInfo});
        });
        let localMobile = this.getLocalMobile();
        if (localMobile != null && localMobile.trim().length > 0) {
            let mobileText = localMobile.substring(0, 4) + "****" + localMobile.substring(8);
            this.setState({mobile: localMobile, mobileText: mobileText, changeMobile: false}, () => {
                DcbPaymentService.checkCustomerMobile(this.state.mobile, (operator: EOperator) => {
                    if (operator != null) {
                        this.setState({operator: operator, checked: true})
                    } else {
                        this.setState({
                            operator: EOperator.UNDEFINED,
                            mobile: "",
                            mobileText: "",
                            changeMobile: false,
                            checked: false
                        })
                    }
                })
            });
        }
        const lottieOption = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        this.setState({lottieOption: lottieOption})
        // 注册native 关闭页面
        nativeClose((data: any) => {
            this.onBackCheck();
        });
    }

    saveLocal(mobile: string) {
        window.localStorage.setItem("lastMobile", mobile);
    }

    getLocalMobile() {
        return window.localStorage.getItem("lastMobile");
    }

    onBackCheck() {
        if (this.state.paymentStatus == "processing") {
            YoleMessageDialog.dialog.openDialog(intl.get('closePaymentInfo'), () => {
                YoleToast.showToastLoading();
                DcbPaymentService.cancelDCBInvoice(this.state.billingNumber, () => {
                    YoleToast.closeToast();
                    this.onBack();
                });
            });
        } else {
            this.onBack();
        }
    }

    onBack() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId);
        }
        if (this.state.intervalId2) {
            window.clearInterval(this.state.intervalId2);
        }
        let cpPage = YoleUtil.getParameterByName("cpPage", window.location.href);
        if (cpPage != null && cpPage.trim().length > 0) {
            let pageUrl = YoleUtil.decodeBase64(cpPage);
            if (pageUrl.indexOf("?") > 0) {
                pageUrl += "&status=" + this.state.paymentStatus + "&billingNumber=" + this.state.billingNumber;
            } else {
                pageUrl += "?status=" + this.state.paymentStatus + "&billingNumber=" + this.state.billingNumber;
            }
            window.location.href = pageUrl;
        } else {
            closeH5Web(this.state.paymentStatus, this.state.billingNumber);
        }
    }

    onPaymentClick() {
        const mobile = this.checkMobile();
        if (mobile.length > 0) {
            if (this.state.checked) {
                this.createInvoice(mobile);
            } else {
                YoleToast.showToastLoading();
                DcbPaymentService.checkCustomerMobile(mobile, (operator: EOperator) => {
                    YoleToast.closeToast();
                    if (operator != null) {
                        this.setState({operator: operator, checked: true}, () => {
                            if (operator == EOperator.BEELINE) {
                                this.setState({showVerify: true})
                            } else {
                                this.createInvoice(mobile);
                            }
                        })
                    } else {
                        this.setState({paymentStatus: "unsupported"});
                    }
                })
            }
        }
    }

    createInvoice(mobile: string) {
        if (this.state.showVerify && (this.state.verifyCode == null || this.state.verifyCode.trim().length < 4)) {
            YoleSnackbars.showText(intl.get('verifyCodeError'), 10000, "error");
            return;
        }
        YoleToast.showToastLoading();
        DcbPaymentService.createDCBInvoiceByH5(this.props.match.params.id, mobile, this.state.operator, (result: YwDcbPaymentResult) => {
            YoleToast.closeToast();
            if (this.state.operator == EOperator.BEELINE) {
                this.saveLocal(mobile);
            } else {
                this.saveLocal("");
            }
            this.setState({paymentStatus: "processing", billingNumber: result.billingNumber}, this.getPaymentStatus);
        })
    }

    onSendSms() {
        if (this.state.second > 0) {
            return;
        }
        let mobile = this.checkMobile();
        if (mobile.length > 0) {
            YoleToast.showToastLoading(intl.get('sending'), 10000);
            // 发送代码
            YoleToast.closeToast();
            this.setState({second: 10});
            const intervalId = window.setInterval(() => {
                if (this.state.second > 0) {
                    this.setState({
                        second: this.state.second - 1,
                    });
                } else {
                    window.clearInterval(this.state.intervalId);
                }
            }, 1000);
            this.setState({intervalId: intervalId});
        }
    }

    checkMobile(): string {
        const regexp = new RegExp("^(\\+7|7|8)?9([0-9]){9}$");
        let mobile: string;
        if (this.state.mobile.startsWith("+79")) {
            mobile = this.state.mobile;
        } else if (this.state.mobile.startsWith("79")) {
            mobile = "+" + this.state.mobile;
        } else if (this.state.mobile.startsWith("89")) {
            mobile = "+" + this.state.mobile;
        } else {
            mobile = "+7" + this.state.mobile;
        }
        const test = regexp.test(mobile);
        if (!test) {
            YoleSnackbars.showText(intl.get('formatError'), 10000, "error");
            return "";
        }
        return mobile;
    }

    onChangeMobile(changed: boolean) {
        this.setState({changeMobile: changed})
    }

    getPaymentStatus() {
        const intervalId2 = window.setInterval(() => {
            DcbPaymentService.getPaymentStatus(this.state.billingNumber, (paymentStatus: YwDcbPaymentStatus) => {
                if (paymentStatus.paymentStatus == "SUCCESSFUL") {
                    this.setState({paymentStatus: "paid"})
                    window.clearInterval(this.state.intervalId2);
                } else if (paymentStatus.paymentStatus == "FAILED") {
                    if (paymentStatus.errorMassage == "PAYMENT_CHANNEL_NOT_ENOUGH_MONEY") {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError2')})
                    } else {
                        this.setState({paymentStatus: "error", errorMassage: intl.get('paymentError1')})
                    }
                    window.clearInterval(this.state.intervalId2);
                } else if (paymentStatus.paymentStatus == "PROCESSING") {
                    this.setState({paymentStatus: "processing"})
                }
            });
        }, 10000);
        this.setState({intervalId2: intervalId2});
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", paddingBottom: 112}}>
            <YoleHeaderBar leftComp={
                <div style={{width: "20%", textAlign: "left"}}>
                    <IconButton onClick={this.onBackCheck}>
                        <ArrowBackIos style={{width: 20, height: 20, color: "black"}}/>
                    </IconButton>
                </div>
            }/>
            <Paper elevation={0} style={{margin: 8}}>
                <div style={{margin: "auto", textAlign: "center", display: "table", width: "100%"}}>
                    <CardMedia style={{width: "35%", paddingTop: "35%", margin: "auto"}}
                               image={this.state.paymentInfo.productImage}
                               title="Product image"/>
                    <Typography style={{color: grey[700], fontWeight: 500}}>
                        {this.state.paymentInfo.productName}
                    </Typography>
                    <Typography variant={"h3"}>
                        {this.state.paymentInfo.amount + " " + this.state.paymentInfo.currencySymbol}
                    </Typography>
                    <Typography variant={"subtitle2"} style={{fontWeight: 500}}>
                        {intl.get('orderNumber') + ":" + this.state.paymentInfo.orderNumber}
                    </Typography>
                </div>
                {this.getMobileSection()}
            </Paper>
            {this.getButtonSection()}
        </div>
    }

    getMobileSection() {
        // return <div style={{textAlign: "center", marginTop: 32}}>
        //     <IconButton disabled={true}>
        //         <CheckCircleOutlineOutlined style={{width: 120, height: 120, color: "#00c853"}}/>
        //     </IconButton>
        //     <Typography variant={"h5"}>
        //         {intl.get('paySuccess')}
        //     </Typography>
        //     <Button variant={"contained"} color={"primary"} style={{marginTop: 16}}>
        //         {intl.get('returnBack')}
        //         <span style={{marginLeft: 4}}>
        //             {this.state.paymentInfo.productName}
        //         </span>
        //     </Button>
        // </div>
        if (this.state.paymentStatus == "error") {
            return <div style={{textAlign: "center"}}>
                <IconButton disabled={true}>
                    <HighlightOff style={{width: 120, height: 120, color: "red"}}/>
                </IconButton>
                <div style={{width: "100%"}}/>
                <Typography variant={"h4"}>
                    {this.state.errorMassage}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "unsupported") {
            return <div style={{textAlign: "center"}}>
                <IconButton disabled={true}>
                    <HighlightOff style={{width: 120, height: 120, color: "red"}}/>
                </IconButton>
                <div style={{width: "100%"}}/>
                <Typography variant={"h4"}>
                    {intl.get('supported')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "processing") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <Lottie options={this.state.lottieOption} height={80}/>
                <Typography variant={"h5"}>
                    {intl.get('payProcessing')}
                </Typography>
            </div>
        } else if (this.state.paymentStatus == "paid") {
            return <div style={{textAlign: "center", marginTop: 32}}>
                <IconButton disabled={true}>
                    <CheckCircleOutlineOutlined style={{width: 120, height: 120, color: "#00c853"}}/>
                </IconButton>
                <Typography variant={"h5"}>
                    {intl.get('paySuccess')}
                </Typography>
                <Button variant={"contained"} color={"primary"} style={{marginTop: 16}} onClick={this.onBackCheck}>
                    {intl.get('returnBack')}
                    <span style={{marginLeft: 4}}>
                    {this.state.paymentInfo.productName}
                </span>
                </Button>
            </div>
        } else {
            if (this.state.mobileText != null && this.state.mobileText.length > 0) {
                return <List component="nav" style={{width: "100%", marginTop: 16}}>
                    <ListItem button onClick={() => this.onChangeMobile(false)}
                              style={{paddingLeft: 32, paddingRight: 32}}>
                        <ListItemText primary={intl.get('lastMobile', {mobile: this.state.mobileText})}/>
                        <Radio color={"primary"} checked={!this.state.changeMobile}/>
                    </ListItem>
                    <ListItem button onClick={() => this.onChangeMobile(true)}
                              style={{paddingLeft: 32, paddingRight: 32}}>
                        <ListItemText primary={intl.get('changePaymentMobile')}/>
                        <Radio color={"primary"} checked={this.state.changeMobile}/>
                    </ListItem>
                </List>
            } else {
                return <div>
                    <div style={{marginTop: 16, paddingLeft: 8, paddingRight: 8}}>
                        <Typography variant={"caption"}>
                            {intl.get('validPhoneNumber')}
                        </Typography>
                        <div style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: 4,
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <div style={{
                                width: "20%", textAlign: "center", borderRight: "1px solid rgba(0, 0, 0, 0.23)",
                                paddingTop: 6, paddingBottom: 8
                            }}>+7
                            </div>
                            <div style={{
                                width: "80%", paddingLeft: 8
                            }}>
                                <InputBase id={"benefitNo"} style={{width: "100%"}}
                                           placeholder={"9XXXXXXXXX"}
                                           value={this.state.mobile}
                                           type="number"
                                           onChange={(event) => {
                                               this.setState({mobile: event.target.value, checked: false})
                                           }}/>
                            </div>
                        </div>
                    </div>
                    {this.state.showVerify &&
                    <div style={{marginTop: 8, paddingLeft: 8, paddingRight: 8}}>
                        <Typography variant={"caption"}>
                            {intl.get('code')}
                        </Typography>
                        <div style={{
                            border: "1px solid rgba(0, 0, 0, 0.23)",
                            borderRadius: 4,
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <div style={{width: "50%", paddingLeft: 8}}>
                                <InputBase id={"benefitNo"} style={{width: "100%", paddingTop: 4, paddingBottom: 4}}
                                           placeholder={intl.get('code')}
                                           value={this.state.verifyCode}
                                           type="number"
                                           onChange={(event) => {
                                               this.setState({verifyCode: event.target.value})
                                           }}/>
                            </div>
                            <div style={{width: "50%", textAlign: "right", marginRight: 2}}>
                                <Button size={"small"} color={"primary"} variant={"contained"} onClick={this.onSendSms}
                                        disabled={this.state.second > 0}>
                                    {this.state.second > 0 ? intl.get('reSend', {seconds: this.state.second}) : intl.get('send')}
                                </Button>
                            </div>
                        </div>
                    </div>}
                    <div style={{marginTop: 16, paddingLeft: 8, paddingRight: 8}}>
                        <Typography variant={"caption"}>
                            {intl.get('supportOperators')}
                        </Typography>
                        <div style={{display: "flex", width: "100%", marginTop: 4, alignItems: "center"}}>
                            <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/megafon_icon.png"}
                                 style={{width: 80}}/>
                            <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/yota_icon.png"}
                                 style={{width: 80, marginLeft: 8}}/>
                            <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/beeline_icon.png"}
                                 style={{width: 80, marginLeft: 8}}/>
                        </div>
                    </div>
                </div>
            }
        }
    }

    getButtonSection() {
        if (this.state.paymentStatus == "unpaid") {
            if (this.state.changeMobile) {
                return <div
                    style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 64, textAlign: "center"}}>
                    <Button variant={"contained"} color={"primary"} style={{width: "75%"}}
                            onClick={() => {
                                this.setState({
                                    operator: EOperator.UNDEFINED,
                                    mobile: "",
                                    mobileText: "",
                                    changeMobile: false,
                                    checked: false
                                })
                            }}>
                        {intl.get('changeMobile')}
                    </Button>
                </div>
            } else {
                return <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 64, textAlign: "center"}}>
                    <Button variant={"contained"} color={"primary"} style={{width: "75%"}}
                            onClick={this.onPaymentClick}>{intl.get('payment')}</Button>
                </div>
            }
        }
        return null;
    }
}
