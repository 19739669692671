import AppClientInfo from "../common/AppClientInfo";
import React from "react";
import AjaxHelper from "../core/AjaxHelper";
import HttpService from "./HttpService";

export default class HttpServiceV2 {
    static getRequestHeaders() {
        const headers: any = [];
        headers.packageName = AppClientInfo.clientInfo.packageName;
        headers.viewMode = AppClientInfo.clientInfo.viewMode;
        headers.content_type = "text/html;charset=UTF-8";
        headers.version = AppClientInfo.clientInfo.version;
        return headers;
    }

    static get(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.get(AppClientInfo.clientInfo.apiServerV2Url + uri, params,
            (data: any) => {
                HttpService.response(successFunction, data, failFunction);
            }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static postFormData(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postFormData(AppClientInfo.clientInfo.apiServerV2Url + uri, params,
            (data: any) => {
                HttpService.response(successFunction, data, failFunction);
            }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static postJson(uri: string, jsonObject: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postJson(AppClientInfo.clientInfo.apiServerV2Url + uri, jsonObject,
            (data: any) => {
                HttpService.response(successFunction, data, failFunction);
            }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }
}
