import {Button, Paper, TextField, Typography} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleToast from "../component/YoleToast";
import DcbPaymentService from "../service/DcbPaymentService";
import YwOnlinePaymentResult from "../model/YwOnlinePaymentResult";
import YoleSnackbars from "../component/YoleSnackbars";

interface OnlinePaymentRefundPageProp extends RouteComponentProps {

}

export class OnlinePaymentRefundPageState {
    billingNumber?: string;
}

export default class OnlinePaymentRefundPage extends React.Component<OnlinePaymentRefundPageProp, OnlinePaymentRefundPageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onPaymentClick = this.onPaymentClick.bind(this);
    }

    componentDidMount(): void {

    }

    onPaymentClick() {
        if (this.state.billingNumber && this.state.billingNumber.trim().length > 0) {
            YoleToast.showToastLoading();
            DcbPaymentService.refund(this.state.billingNumber, (result: string) => {
                YoleToast.closeToast();
                YoleSnackbars.snackbar.show(result);
            })
        } else {
            YoleSnackbars.showText("Please enter the billingNumber", 10000, "error");
        }
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", paddingBottom: 112}}>
            <div style={{paddingTop: 112}}/>
            <Paper elevation={0} style={{margin: 8}}>
                <div style={{margin: "auto", textAlign: "center", display: "table"}}>
                    <Typography variant={"h3"}>Refund test page</Typography>
                </div>
                <div style={{marginTop: 16, paddingLeft: 8, paddingRight: 8}}>
                    <TextField label="BillingNumber" variant="outlined" value={this.state.billingNumber}
                               style={{width: "100%"}}
                               onChange={(event) => {
                                   this.setState({billingNumber: event.target.value})
                               }}/>
                </div>
            </Paper>
            <div style={{width: "100%", maxWidth: 580, position: "fixed", bottom: 64, textAlign: "center"}}>
                <Button variant={"contained"} color={"primary"} style={{width: "75%"}} onClick={this.onPaymentClick}>
                    Refund
                </Button>
            </div>
        </div>
    }
}
