export enum EOperator {
    MEGAFON = "MEGAFON",
    BEELINE = "BEELINE",
    TELE2 = "TELE2",
    MOTIV = "MOTIV",
    MTS = "MTS",
    TINKOFF = "TINKOFF",
    YOTA = "YOTA",
    UNDEFINED = "UNDEFINED"
}
