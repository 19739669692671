import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleUtil from "../util/YoleUtil";

declare function closeH5Web(status: string, billingNumber: string): void;

declare function nativeClose(callback: (data: any) => void): void;

interface GamePaymentResultPageProp extends RouteComponentProps {

}

export class GamePaymentResultPageState {

}

export default class GamePaymentResultPage extends React.Component<GamePaymentResultPageProp, GamePaymentResultPageState> {

    constructor(props: any) {
        super(props);
        let billingNumber = YoleUtil.getParameterByName("billingNumber", window.location.href);
        let paymentStatus = YoleUtil.getParameterByName("status", window.location.href);
        closeH5Web(billingNumber, paymentStatus);
    }

    componentDidMount(): void {

    }

    render() {
        return <div>

        </div>
    }
}
