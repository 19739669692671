import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {Button, IconButton, Typography} from "@material-ui/core";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleUtil from "../util/YoleUtil";

declare function closeH5Web(status: string, billingNumber: string): void;

declare function nativeClose(callback: (data: any) => void): void;

interface YwsPaymentResultV2PageProp extends RouteComponentProps <{ status: string, billingNumber?: string }> {

}

export class YwsPaymentResultV2PageState {
    paymentStatus: string = "";
    billingNumber: string = "";
}

export default class YwsPaymentResultV2Page extends React.Component<YwsPaymentResultV2PageProp, YwsPaymentResultV2PageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            paymentStatus: this.props.match.params.status,
            billingNumber: this.props.match.params.billingNumber + "",
        };
        this.onBack = this.onBack.bind(this);
    }

    componentDidMount(): void {
        // 注册native 关闭页面
        nativeClose(() => {
            this.onBack();
        });
        if (this.state.paymentStatus == "closePage") {
            this.onBack();
        }
    }

    onBack() {
        closeH5Web(this.state.paymentStatus, this.state.billingNumber);
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", height: "100%", backgroundColor: grey[100]}}>
            <YoleHeaderBar style={{backgroundColor: "white", borderWidth: 0}}
                           leftComp={
                               <div style={{width: "20%", textAlign: "left"}}>
                                   <IconButton onClick={this.onBack}>
                                       <img src={"https://yolewallet.oss-accelerate.aliyuncs.com/web/image/on_back.jpg"}
                                            alt={"Back"}
                                            style={{width: YoleUtil.calculateElementWidth(38)}}/>
                                   </IconButton>
                               </div>
                           }
                           centerComp={
                               <div style={{width: "60%", textAlign: "center"}}>
                                   <Typography
                                       style={{fontWeight: "bold", fontSize: YoleUtil.calculateElementWidth(34)}}>
                                       Payment
                                   </Typography>
                               </div>
                           }/>
            <div style={{marginTop: YoleUtil.calculateElementWidth(210)}}>
                {this.state.paymentStatus == "PAID" &&
                <div style={{textAlign: "center"}}>
                    <div style={{
                        width: YoleUtil.calculateElementWidth(160),
                        height: YoleUtil.calculateElementWidth(160),
                        margin: "auto",
                        backgroundImage: "url(https://yolewallet.oss-accelerate.aliyuncs.com/web/image/pay_success.png)",
                        backgroundSize: "cover",
                    }}/>
                    <Typography style={{
                        fontWeight: "bold",
                        fontSize: YoleUtil.calculateElementWidth(42),
                        marginTop: YoleUtil.calculateElementWidth(60),
                    }}>{"Payment successful"}</Typography>
                </div>}
                {this.state.paymentStatus != "PAID" &&
                <div style={{textAlign: "center"}}>
                    <div style={{
                        width: YoleUtil.calculateElementWidth(160),
                        height: YoleUtil.calculateElementWidth(160),
                        margin: "auto",
                        backgroundImage: "url(https://yolewallet.oss-accelerate.aliyuncs.com/web/image/pay_failed.png)",
                        backgroundSize: "cover",
                    }}/>
                    <Typography style={{
                        fontWeight: "bold",
                        fontSize: YoleUtil.calculateElementWidth(42),
                        marginTop: YoleUtil.calculateElementWidth(60),
                    }}>{"Payment failed"}</Typography>
                </div>}
                <div style={{
                    textAlign: "center",
                    marginTop: YoleUtil.calculateElementWidth(160),
                    paddingLeft: 20, paddingRight: 20
                }}>
                    <Button variant={"contained"} onClick={this.onBack}
                            style={{
                                width: "100%",
                                paddingTop: YoleUtil.calculateElementWidth(26),
                                paddingBottom: YoleUtil.calculateElementWidth(26),
                                backgroundColor: "#5F74FF"
                            }}>
                        <Typography
                            style={{fontSize: YoleUtil.calculateElementWidth(36), fontWeight: "bold", color: "white"}}>
                            {"Return to home page"}
                        </Typography>
                    </Button>
                </div>
            </div>
        </div>
    }
}
