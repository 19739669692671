export default class AppClientInfo {

    public static clientInfo: AppClientInfo = new AppClientInfo();

    apiServerUrl: string = "";
    apiServerV2Url: string = "";
    packageName: string = "";
    version: string = "";
    availHeight: number = 0;
    availWidth: number = 0;
    viewMode: "mobile" | "pad" | "pc" = "mobile";
    appkey: string = "";
    userCode: string = "";
}
