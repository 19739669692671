import AppClientInfo from "../common/AppClientInfo";
import YoleSnackbars from "../component/YoleSnackbars";
import React from "react";
import YoleToast from "../component/YoleToast";
import AjaxHelper from "../core/AjaxHelper";

export default class HttpService {
    static getRequestHeaders() {
        const headers: any = [];
        headers.packageName = AppClientInfo.clientInfo.packageName;
        headers.viewMode = AppClientInfo.clientInfo.viewMode;
        headers.content_type = "text/html;charset=UTF-8";
        headers.version = AppClientInfo.clientInfo.version;
        headers.appkey = AppClientInfo.clientInfo.appkey;
        headers.userCode = AppClientInfo.clientInfo.userCode;
        return headers;
    }

    static get(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.get(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: any, textStatus: string, response: any) => {
            HttpService.response(successFunction, data, failFunction);
        }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static postFormData(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postFormData(AppClientInfo.clientInfo.apiServerUrl + uri, params, (data: any, textStatus: string, response: any) => {
            HttpService.response(successFunction, data, failFunction);
        }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static postJson(uri: string, jsonObject: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postJson(AppClientInfo.clientInfo.apiServerUrl + uri, jsonObject,
            (data: any, textStatus: string, response: any) => {
                HttpService.response(successFunction, data, failFunction);
            }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static handleError(apiResponse: any): void {
        YoleToast.closeToast();
        if (apiResponse && apiResponse.message) {
            YoleSnackbars.showText("Error：" + apiResponse.message, 6000, "error");
        }
    }

    static response(successFunction: (response: any) => void, data: any, failFunction?: (error: any) => void) {
        console.log(data);
        if (data && data.status != null && data.status == "SUCCESS") {
            successFunction(data.content);
        } else {
            YoleToast.closeToast();
            if (failFunction)
                failFunction(data);
            else {
                HttpService.handleError(data);
            }
        }
    }
}
